import React, { useState } from 'react';
import Login from './Login';
import StudentCoupons from './StudentCoupons';
import './App.css';

function App() {
  const [token, setToken] = useState('');

  return (
    <div className="App">
      <header className="App-header">
        <h1>DHBW Heidenheim Studenten Coupons</h1>
        {!token ? (
          <Login setToken={setToken} />
        ) : (
          <StudentCoupons token={token} />
        )}
      </header>
    </div>
  );
}

export default App;