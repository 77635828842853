import React, { useState, useEffect } from 'react';
import QRCode from 'qrcode.react';
import './StudentCoupons.css';

function StudentCoupons({ token }) {
  const [coupons, setCoupons] = useState([]); // Alle Coupons
  const [filteredCoupons, setFilteredCoupons] = useState([]); // Gefilterte Coupons
  const [partners, setPartners] = useState([]); // Geschäftspartner
  const [selectedPartner, setSelectedPartner] = useState(''); // Ausgewählter Geschäftspartner

  useEffect(() => {
    // Geschäftspartner abrufen
    const fetchPartners = async () => {
      try {
        const response = await fetch('http://85.215.136.123/api/partners', {
          method: 'GET',
          headers: {
            Authorization: token,
            'Content-Type': 'application/json',
          },
        });
        const data = await response.json();
        setPartners(data);
      } catch (error) {
        console.error('Error fetching partners:', error);
      }
    };

    // Alle Coupons abrufen
    const fetchCoupons = async () => {
      try {
        const response = await fetch('http://85.215.136.123/api/students/coupons', {
          method: 'GET',
          headers: {
            Authorization: token,
            'Content-Type': 'application/json',
          },
        });
        const data = await response.json();
        setCoupons(data);
        setFilteredCoupons(data); // Zeige standardmäßig alle Coupons an
      } catch (error) {
        console.error('Error fetching coupons:', error);
      }
    };

    fetchPartners();
    fetchCoupons();
  }, [token]);

  // Funktion zum Anwenden des Filters
  const handleFilterChange = (e) => {
    const partnerId = e.target.value;
    setSelectedPartner(partnerId);

    if (partnerId) {
      // Filtere die Coupons basierend auf dem ausgewählten Geschäftspartner
      const filtered = coupons.filter(coupon => coupon.PartnerID === parseInt(partnerId));
      setFilteredCoupons(filtered);
    } else {
      // Wenn kein Geschäftspartner ausgewählt ist, zeige alle Coupons an
      setFilteredCoupons(coupons);
    }
  };

  const redeemCoupon = async (couponId) => {
    try {
      const response = await fetch('http://85.215.136.123/api/students/coupons/redeem', {
        method: 'POST',
        headers: {
          Authorization: token,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ couponId }),
      });

      if (response.ok) {
        // Couponstatus aktualisieren und direkt neu rendern
        setCoupons(coupons.map(coupon =>
          coupon.AktionID === couponId ? { ...coupon, Einlösedatum: new Date().toISOString() } : coupon
        ));

        alert('Coupon redeemed successfully');

        // Auch gefilterte Coupons aktualisieren, damit die Ansicht sofort verändert wird
        setFilteredCoupons(filteredCoupons.map(coupon =>
          coupon.AktionID === couponId ? { ...coupon, Einlösedatum: new Date().toISOString() } : coupon
        ));
      } else {
        alert('Coupon already redeemed or does not exist. Please reload the page');
      }
    } catch (error) {
      alert('Error redeeming coupon');
      console.error('Error redeeming coupon:', error);
    }
  };

  // Funktion zum Einlösen über QR-Code-Scan
  const redeemByQRCode = async (couponData) => {
    try {
      const { couponId, token } = couponData;
      const response = await fetch(`http://85.215.136.123/api/redeem?couponId=${couponId}&token=${token}`);
      
      if (response.ok) {
        alert('Coupon redeemed successfully!');
        setCoupons(coupons.map(coupon =>
          coupon.AktionID === couponId ? { ...coupon, Einlösedatum: new Date().toISOString() } : coupon
        ));
        setFilteredCoupons(filteredCoupons.map(coupon =>
          coupon.AktionID === couponId ? { ...coupon, Einlösedatum: new Date().toISOString() } : coupon
        ));
      } else {
        alert('Error redeeming coupon');
      }
    } catch (error) {
      console.error('Error redeeming coupon via QR:', error);
    }
  };

  const generateQRCodeValue = (coupon) => {
    const token = localStorage.getItem('token'); // Der gespeicherte Token
    const studentId = coupon.StudentID; // Die StudentID vom Coupon
    return `http://85.215.136.123/api/redeem?couponId=${coupon.AktionID}&studentId=${studentId}&token=${token}`;
  };

  return (
    <div className="coupon-container">
      <h1>Your Coupons</h1>
      
      {/* Dropdown-Menü für die Geschäftspartnerauswahl */}
      <select onChange={handleFilterChange} value={selectedPartner}>
        <option value="">Select a Partner</option>
        {partners.map(partner => (
          <option key={partner.PartnerID} value={partner.PartnerID}>{partner.Name}</option>
        ))}
      </select>

      <div className="coupon-grid">
        {filteredCoupons.map(coupon => (
          <div className="coupon-card" key={coupon.AktionID}>
            <h2>{coupon.Bezeichnung}</h2>
            <p>{coupon.Beschreibung}</p>
            <p>Expires on: {coupon.Ablaufdatum}</p>
            {!coupon.Einlösedatum && <QRCode value={generateQRCodeValue(coupon)} />}
            <p>Status: {coupon.Einlösedatum ? 'Redeemed' : 'Available'}</p>
            {!coupon.Einlösedatum && (
              <button onClick={() => redeemCoupon(coupon.AktionID)}>Redeem</button>
            )}
          </div>
        ))}
      </div>
    </div>
  );
}

export default StudentCoupons;
